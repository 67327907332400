import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "nordic fighter" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "träningsutrustning-från-nordic-fighter"
    }}>{`Träningsutrustning från Nordic Fighter`}</h1>
    <p>{`Upptäck kraften i din träning med Nordic Fighter, en ledande tillverkare av högkvalitativ träningsutrustning som möter behoven hos både nybörjare och proffs. Med ett brett sortiment av träningsredskap, inklusive bänkar, gummiband, lyftarplattformar och mycket mer, garanterar Nordic Fighter att din träning blir både effektiv och säker. Låt oss utforska de olika serierna från Nordic Fighter och hur de kan ta din träning till nästa nivå.`}</p>
    <h2 {...{
      "id": "tf-standard-serien"
    }}>{`TF Standard-serien`}</h2>
    <h3 {...{
      "id": "tf-standard-flat-bench"
    }}>{`TF Standard Flat Bench`}</h3>
    <p>{`TF Standard Flat Bench är en professionell träningsbänk designad för hög effektivitet och hållbarhet. Perfekt både för hemmagym och kommersiella gym, denna bänk kombinerar robust konstruktion med en smidig och lätt flyttbar design, tack vare sina praktiska hjul.`}</p>
    <h3 {...{
      "id": "tf-standard-fid-bench"
    }}>{`TF Standard FID Bench`}</h3>
    <p>{`Upptäck TF Standard FID Bench, en justerbar träningsbänk som erbjuder mångsidighet för alla typer av pressövningar. Oavsett om det handlar om bänkpress, axelpress eller hantelpress, garanterar dess justerbara rygg- och sittstöd komfort och stabilitet.`}</p>
    <h2 {...{
      "id": "mini-bands-serien"
    }}>{`Mini Bands-serien`}</h2>
    <h3 {...{
      "id": "nordic-fighter-mini-band-svart-110-kg"
    }}>{`Nordic Fighter Mini Band Svart, 110 kg`}</h3>
    <p>{`Perfekt för att öka styrka och flexibilitet, erbjuder detta motståndsband hela 110 kg motstånd, vilket gör det lämpligt för både nybörjare och erfarna atleter.`}</p>
    <h3 {...{
      "id": "nordic-fighter-mini-band-röd-14-kg"
    }}>{`Nordic Fighter Mini Band Röd, 14 kg`}</h3>
    <p>{`Idealisk för styrketräning och flexibilitetsövningar, detta band erbjuder en motståndsnivå på 14 kg och är ett utmärkt verktyg för att förbättra din träning.`}</p>
    <h3 {...{
      "id": "nordic-fighter-mini-band-orange-7-kg"
    }}>{`Nordic Fighter Mini Band Orange, 7 kg`}</h3>
    <p>{`Med 7 kg i motstånd är detta band perfekt för mångsidig hemmaträning och förbättrar styrka och flexibilitet på ett effektivt sätt.`}</p>
    <h3 {...{
      "id": "nordic-fighter-mini-band-blå-23-kg"
    }}>{`Nordic Fighter Mini Band Blå, 23 kg`}</h3>
    <p>{`Ett mångsidigt gummiband som erbjuder 23 kg motstånd, perfekt för att stärka både styrka och flexibilitet.`}</p>
    <h3 {...{
      "id": "nordic-fighter-mini-band-grön-30-kg"
    }}>{`Nordic Fighter Mini Band Grön, 30 kg`}</h3>
    <p>{`Med 30 kg motstånd är detta band idealiskt för atleter som vill maximera sina träningssessioner och förbättra sin fysik.`}</p>
    <h3 {...{
      "id": "nordic-fighter-mini-band-lila-80-kg"
    }}>{`Nordic Fighter Mini Band Lila, 80 kg`}</h3>
    <p>{`Den ultimata träningsbandet med 80 kg motstånd som passar för de mest intensiva träningspassen.`}</p>
    <h2 {...{
      "id": "powerbands-serien"
    }}>{`Powerbands-serien`}</h2>
    <h3 {...{
      "id": "nordic-fighter-powerbands-orange-05-7-kg"
    }}>{`Nordic Fighter Powerbands Orange, 0,5-7 kg`}</h3>
    <p>{`Dessa gummiband är perfekta för en bred variation av övningar, från stretching till styrketräning, och erbjuder ett dynamiskt motstånd mellan 0,5-7 kg.`}</p>
    <h3 {...{
      "id": "nordic-fighter-powerbands-röd-2-14-kg"
    }}>{`Nordic Fighter Powerbands Röd, 2-14 kg`}</h3>
    <p>{`Med ett motstånd på 2-14 kg är dessa band utmärkta för att förbättra både kondition och flexibilitet genom diverse övningar.`}</p>
    <h3 {...{
      "id": "nordic-fighter-powerbands-grön-12-30-kg"
    }}>{`Nordic Fighter Powerbands Grön, 12-30 kg`}</h3>
    <p>{`Perfekta för att assistera med chins, marklyft och bänkpress, och med ett motstånd på 12-30 kg, gör denna modell träningen mer dynamisk.`}</p>
    <h3 {...{
      "id": "nordic-fighter-powerbands-blå-5-23-kg"
    }}>{`Nordic Fighter Powerbands Blå, 5-23 kg`}</h3>
    <p>{`Band med ett motstånd på 5-23 kg, ideala för både förstärkning av styrketräning och stretching av stora muskelgrupper.`}</p>
    <h3 {...{
      "id": "nordic-fighter-powerbands-svart-20-45-kg"
    }}>{`Nordic Fighter Powerbands Svart, 20-45 kg`}</h3>
    <p>{`Ett högkvalitativt band med 20-45 kg motstånd som erbjuder intensiv styrketräning och stretching.`}</p>
    <h3 {...{
      "id": "nordic-fighter-powerbands-lila-30-80-kg"
    }}>{`Nordic Fighter Powerbands Lila, 30-80 kg`}</h3>
    <p>{`Dessa kraftiga och flexibla band med motstånd om 30-80 kg är designade för att maximera din styrke- och flexibilitetsträning.`}</p>
    <h2 {...{
      "id": "fid-utility-bench"
    }}>{`FID Utility Bench`}</h2>
    <p>{`Nordic Fighter FID Utility Bench är en mångsidig träningsbänk som är idealisk för hemmagym. Dess justerbarhet och robusthet gör den till ett utmärkt val för en bred variation av övningar.`}</p>
    <h2 {...{
      "id": "gummigolv"
    }}>{`Gummigolv`}</h2>
    <h3 {...{
      "id": "nordic-fighter-gummigolv-17mm-vulkaniserat-1x1m"
    }}>{`Nordic Fighter Gummigolv 17mm Vulkaniserat 1x1m`}</h3>
    <p>{`Ett hållbart och enkelt installerat gymgolv som erbjuder stabilitet och komfort, perfekt för både hemmagym och kommersiella miljöer.`}</p>
    <h3 {...{
      "id": "nf-svart-gymgolv-30mm-1x1m-raka-kanter"
    }}>{`NF Svart Gymgolv 30mm 1x1m, Raka Kanter`}</h3>
    <p>{`Detta ultratåliga gymgolv dämpar ljud och stötar optimalt. Perfekt för marklyft och andra tunga övningar.`}</p>
    <h2 {...{
      "id": "gymnastic-grips"
    }}>{`Gymnastic Grips`}</h2>
    <h3 {...{
      "id": "nordic-fighter-gymnastic-grips-smallmediumlargexl"
    }}>{`Nordic Fighter Gymnastic Grips Small/Medium/Large/XL`}</h3>
    <p>{`Slitstarka träningshandskar designade för optimalt grepp och skydd under CrossFit och gymnastikträning.`}</p>
    <h2 {...{
      "id": "buying-guide-vilken-serie-ska-du-välja"
    }}>{`Buying Guide: Vilken serie ska du välja?`}</h2>
    <h3 {...{
      "id": "tf-standard-serien-1"
    }}>{`TF Standard-serien`}</h3>
    <p>{`Perfekt för dig som söker robusta och justerbara träningsbänkar för både hemmagym och professionella miljöer.`}</p>
    <h3 {...{
      "id": "mini-bands-serien-1"
    }}>{`Mini Bands-serien`}</h3>
    <p>{`Ett utmärkt val för både nybörjare och erfarna atleter som vill förbättra styrka och flexibilitet med mångsidiga motståndsband.`}</p>
    <h3 {...{
      "id": "powerbands-serien-1"
    }}>{`Powerbands-serien`}</h3>
    <p>{`Perfekt för de som vill ha mångsidiga redskap för styrketräning, stretching och assisterande övningar som chins och marklyft.`}</p>
    <h3 {...{
      "id": "fid-utility-bench-1"
    }}>{`FID Utility Bench`}</h3>
    <p>{`Idealisk för de som behöver en justerbar och robust träningsbänk för en mängd olika övningar.`}</p>
    <h3 {...{
      "id": "gummigolv-1"
    }}>{`Gummigolv`}</h3>
    <p>{`För både hemmagym och kommersiella miljöer som kräver hållbara och ljuddämpande golvlösningar.`}</p>
    <h3 {...{
      "id": "gymnastic-grips-1"
    }}>{`Gymnastic Grips`}</h3>
    <p>{`Optimala för dem som vill skydda sina händer under intensiv träning såsom CrossFit och gymnastik.`}</p>
    <p>{`Utforska Nordic Fighter träningsutrustning och hitta de perfekta redskapen för din fitnessresa. Skapa ditt drömgym med premiumkvalitet och oöverträffad prestanda.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      